/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/demo/documentation.scss";
@import "assets/sass/layout/_variables";
@import "assets/sass/layout/_layout";
@import "assets/sass/overrides/_overrides";

/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.header-actions {
    display: flex;
    justify-content: end;
}
